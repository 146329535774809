<template>
  <div>
    <div class="modal fade modalFolderDeleteDefault" :id="nameModalDelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content containerMain">
          <div class="modal-header pb-0">
             <div class="menu-close" data-dismiss="modal">
                <label id="btnClose">
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div>
          </div>
          <div class="modal-body pb-0">

            <div>
              <h1 v-if="item.name"> Deseja Excluir a Subcategoria <b>{{ item.name }}</b> e os seus arquivos?</h1>
              <h1 v-else> Deseja Excluir a Pasta <b>{{ item.nameFolder }}</b> e os seus arquivos?</h1>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn_secondary" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-danger" @click="$emit('deleteFolder', item.id)"> Deletar </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'nameModalDelete'],
}
</script>

<style scoped>
.menu,
.menu-close {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#checkbox-menu,
#btnClose {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 30px;
  margin: 0;
}

#btnClose span {
  position: absolute;
  display: block;
  border-radius: 30px;
  background: #dc3545;
  transition: 0.25s ease-in-out;
  width: 36px;
  border: 2px solid #dc3545;
}


#btnClose span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#btnClose span:nth-child(2) {
  opacity: 0;
}

#btnClose span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

.modalFolderDeleteDefault .containerMain {
  background: #FFFFFF;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.modalFolderDeleteDefault .modal-header {
  border: none;
  display: flex;
  flex-direction: row-reverse;
}

.labelTitle {
  color: #A5A6AD !important;
}

.modalFolderDeleteDefault h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #2474B2;
}

.modalFolderDeleteDefault .main > div {
  display: flex;
  flex-direction: column;
}

.modalFolderDeleteDefault .main .checkContainer{
  display: flex;
}

.modalFolderDeleteDefault .main label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  cursor: pointer;
}

.modalFolderDeleteDefault .main input {
  background: #FFFFFF;
  border: 1px solid #98C4E6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
}

.checkContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 0;
}
.checkContainer div label{
  margin-left: 5px;
}

.btn {
  height: 40px;
  width: auto;
  border: none;
  border-radius: 6px;
  align-items: center;
  outline: none;
  width: 120px;
  padding: 0 20px;
}

.btn_secondary {
  color: #2474B2;
  border: #2474B2 1px solid;
}

b {
  color: #dc3545;
}


</style>
